const data = [
  {
    name: "Guidelines of Examination & Promotion for classes VI - IX & XI ",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2024-25/041-GUIDELINES-FOR-EXAMINATION-AND-PROMOTION-2024-25.pdf",
    date: "25/09/2024",
  },
  {
    name: "Fee Circular",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/fee-circular-2024-25.pdf",
    date: "10/04/2024",
  },
  {
    name: "New Session Circular 2024-25",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/New-Session-Circular-2024-25.pdf",
    date: "02-04-2024",
  },
  {
    name: "Information for Classes Nursery to IX - Orientation Program for New Admissions Student",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Orientation-Letter-2024.pdf",
    date: "19-03-2024",
  },
  {
    name: "Circular For Books",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2024-25/Circular-for-Books-2024-25.pdf",
    date: "06-03-2024",
  },
  {
    name: "Circular For Uniform",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2024-25/Circular-for-Uniform-2024-25.pdf",
    date: "04-03-2024",
  },
  {
    name: "Withdrawl Notification Session 2023-2024",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/CIRCULAR-WITHDRAWAL-NOTIFICATION-SESSION-2023-2024.pdf",
    date: "19-02-2024",
  },
  {
    name: "Revised Fee Circular",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/REVISED-FEE-CIRCULAR-SESSION-2023-2024.pdf",
    date: "15-02-2024",
  },
  {
    name: "Annual Examination PTM and availability of books and uniforms for classes Nursery to XII",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/003-Circular-Annual-Examination-PTM-and-availability-of-Books-and-Uniforms.pdf",
    date: "08-02-2024",
  },
  {
    name: "Subject allocation in class XI (Stream options 2024-25)",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/001-Subject-allocation-in-class-XI-(Stream-options-2024-25).pdf",
    date: "25-01-2024",
  },

  {
    name: "Information of Admission 2023-24 - Circular regarding EWS admission (High Court matter)",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Information-of-Admission-2023-24-(Circular-regarding-EWS-admission-(High-Court-matter).pdf",
    date: "04-11-2023",
  },
  {
    name: "Skill Training Session",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/020-Skill-Training-Session.pdf",
    date: "11-07-2023",
  },
  {
    name: "Examination Guidelines (Classes VI-IX & XI) 2023-24",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/EXAMINATION-GUIDELINES-(CLASSES-VI-IX-&-XI)-2023-24.pdf",
    date: "29-05-2023",
  },
  {
    name: "Examination Guidelines (Classes III-V) 2023-24",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Examination-Guidelines-(Class-III-V)-2023-24.pdf",
    date: "25-05-2023",
  },
  {
    name: "Sports Summer Camp 2023",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Sports-Summer-Camp-2023.pdf",
    date: "11-05-2023",
  },
  {
    name: "School Fees Rules & Regulations",
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/SCHOOL-FEES-RULES-AND-REGULATIONS.pdf",
    date: "26-04-2023",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/LIST-OF-HOLIDAYS(2023-2024).pdf",
    date: "25-04-2023",
    name: "List of holidays",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/010-Club-Activity-Circular-2023-24.pdf",
    date: "11-04-2023",
    name: "Club Activity Circular 2023 - 2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/Fee-circular-march-2023.pdf",
    date: "29-03-2023",
    name: "Fee Circular: Payment of the School Fees for the Academic session 2023-2024",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/New-Session-Circular-march-2023.pdf",
    date: "29-03-2023",
    name: "New Session Circular",
  },
  {
    link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2022-23-Annual-Examination-PTM-and-for-the-availability-of-Books-Uniforms-Circular.pdf",
    date: "29-03-2023",
    name: "2022-23 Annual Examination PTM and for the availability of Book & Uniforms",
  },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2022-23-Annual-Examination-PTM-and-for-the-availability-of-Books-Uniforms-Circular.pdf",
  //   date: "07-02-2023",
  //   name: "2022-23 Annual Examination PTM and for the availability of Book & Uniforms",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/School-Timings-for-Classes-Nursery-and-Prep.pdf",
  //   date: "07-02-2023",
  //   name: "School Timings for Classes Nursery and Prep",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/School-Timings-for-Classes-I-&-II.pdf",
  //   date: "03-02-2023",
  //   name: "School Timings Classes I & II",
  // },
  // {
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/circulars/2nd-Language-option.pdf",
  //   date: "03-02-2023",
  //   name: "Second language option",
  // },
  // {
  //   date: "08-09-2022",
  //   name: "Circular - Half Yearly Examination, Class I & II, 2022-2023",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/exam-schedules/HALF-YEARLY-EXAMINATION-CIRCULAR-I-&-II(2022-23).pdf",
  // },
  // {
  //   date: "28-02-2022",
  //   name: "Circular - Annual Exam Result and Academic Session 2022-23",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/001-Annual-Exam-Result-and-Academic-Session-2022-23.pdf",
  // },
  // {
  //   date: "10-09-2021",
  //   name: "School Reopening for Class IX - XII",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/School-Reopening.pdf",
  // },
  // {
  //   date: "31-03-2021",
  //   name: "School Circular for Academic Session 2021-22",
  //   link: "https://chinmayavvdelhi.ac.in/assets/pdfs/Circular-Academic-Session-2021-22.pdf",
  // },
  // {
  //   date: "11-02-2021",
  //   name:
  //     "Criteria for Pre-School (Nursery) Admission for the Academic Session 2021-22",
  //   link:
  //     "https://chinmayavvdelhi.ac.in/assets/pdfs/CHINMAYA-VIDYALAYA-Nursery-Admission-Criteria-2021.pdf",
  // },
];

export default data;
