import React from "react";
import "./style.scss";
import data from "./data";
import Layout from "../../Layout";
import SEO from "../../seo";

export default function SchoolCircular() {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya School Circulars"
        description="Chinmaya Vidyalaya, Vasant Vihar, New Delhi. Know more about School circulars inforamtion."
        keywords="Chinmaya Vidyalaya School Circulars"
      />
      <div className="schoolcircular">
        <h1 className="heading">School Circulars</h1>
        <div className="schoolcircular__table">
          <div className="schoolcircular__table__row schoolcircular__table__row--heading">
            <div className="schoolcircular__table__col1">Date Of Issue</div>
            <div className="schoolcircular__table__col2">Circular Name</div>
          </div>
          {data.map((item, i) => (
            <div
              key={`schoolcicular${i}`}
              className="schoolcircular__table__row"
            >
              <div className="schoolcircular__table__col1">{item.date}</div>
              <div className="schoolcircular__table__col2">{item.name}</div>
              <div className="schoolcircular__table__col3">
                <a href={item.link} download>
                  <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
